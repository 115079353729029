import React from "react"
import Trainers from "../components/trainers/trainers"
import SEO from "../components/seo"

const Page = location => (
  <>
    <SEO
      title="Trainers"
      description="Trainers page"
      url="/trainers/"
      image="https://blogs.mycareerdreams.com/blogs/tips_header.webp"
      type="article"
    />
    <Trainers location={location} />
  </>
)
export default Page
